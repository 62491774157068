export default {
  computed: {
    type () {
      if (this.$route.params.type) {
        return this.$route.params.type
      }
    },

    /**
     * Return one of menu
     * @return {*|*[]}
     */
    menuList () {
      return this.menu[this.type] || []
    },

    /**
     * Return current route
     * @returns {{route: {name: string}, name: string, menu: boolean, title: boolean}}
     */
    currentRoute () {
      return this.menuList[this.currentRouteIndex()]
    }
  },
  mounted() {
    /**
     * Detect default route by type
     */
    if (!this.currentRoute) {
      let route = this.findNextIncompleteRoute()
      return this.$router.push(route)
    }
  },
  methods: {
    /**
     * Move to next step
     *
     * @param {boolean} params.skipCompleted
     */
    goNext (params = {}) {
      let route = {}

      if (params.skipCompleted) {
        route = this.findNextIncompleteRoute()
      } else {
        route = this.findNextRoute()
      }

      return this.$router.push(route)
    },

    /**
     * Return index of current route
     * @returns {number}
     */
    currentRouteIndex () {
      return this.menuList.findIndex((item) => {
        return item.route.name === this.$route.name
      })
    },

    /**
     * Return next route
     *
     * @return {*}
     */
    findNextRoute () {
      let index = this.currentRouteIndex();
      return this.menuList[++index].route
    },

    /**
     * Return next incomplete step's route
     *
     * @return {*}
     */
    findNextIncompleteRoute () {
      for (let i = this.currentRouteIndex(); i < this.menuList.length; i++) {
        let item = this.menuList[i]

        if (item && !this.isCompletedValidate(item)) {
          return item.route
        }
      }
    },

    /**
     * Check isCompleted field of menuItem
     *
     * @param item
     * @return {boolean|*}
     */
    isCompletedValidate (item) {
      const type = typeof item.isCompleted

      if (!['boolean', 'function', 'undefined'].includes(type)) {
        throw new Error('Type of item can be only boolean or function. Found: ' + type)
      }

      if (typeof item.isCompleted === 'function') {
        return item.isCompleted()
      }

      if (typeof item.isCompleted === 'boolean') {
        return item.isCompleted
      }

      return false
    }
  },
}
