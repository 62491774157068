<template>
  <div class="container center-block" v-if="currentRoute">
    <div class="main-title" v-if="currentRoute.title">{{ titleList[type] }}</div>

    <div :class="{'grid-column-3-1': currentRoute.menu}">
      <router-view :type="type" v-on:next-step="goNext"/>
      <router-menu v-if="currentRoute.menu" :items="menu[type]" :fixed="menuFixed"/>
    </div>
  </div>
</template>

<script>
import RouterMenu from '@/views/Menu'
import LayoutMixin from "@/components/LayoutMixin";
import {mapState} from 'vuex'

/**
 * @property currentRoute
 * @method goNext
 */
export default {
  name: 'Home',
  mixins: [LayoutMixin],
  components: {
    RouterMenu
  },
  filters: {
    capitalize (string = '') {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  },
  data () {
    return {
      menuFixed: false,
      titleList: {
        'business': 'Business account',
        'individual': 'Personal account',
      }
    }
  },
  computed: {
    ...mapState({
      hash: state => state.registration.hash,
      data: state => state.registration.data,
      type: state => state.registration.type
    }),

    /**
     * List of available routes
     *
     * @return {{business: ({route: {name: string}, name: string, menu: boolean, title: boolean}|{route: {name: string, params: {hash}}, name: string, menu: boolean, title: boolean}|{route: {name: string, params: {hash}}, name: string, menu: boolean, title: boolean}|{route: {name: string, params: {hash}}, name: string, menu: boolean, title: boolean}|{route: {name: string, params: {hash}}, name: string, menu: boolean, title: boolean})[], individual: [{route: {name: string}, name: string, menu: boolean, title: boolean}, {route: {name: string, params: {hash: goNext.computed.hash}}, name: string, menu: boolean, title: boolean}, {route: {name: string, params: {hash: goNext.computed.hash}}, name: string, menu: boolean, title: boolean}, {route: {name: string}, name: string, menu: boolean, title: boolean}]}}
     */
    menu () {
      return {
        business: [
          {
            name: 'Create Account',
            route: {name: 'CreateAccount'},
            menu: false,
            title: true,
            isCompleted: () => {
              return this.data.user.isCompleted
            }
          },
          {
            name: 'Verification',
            route: {name: 'Verification', params: {hash: this.hash}},
            menu: false,
            title: false,
            isCompleted: () => {
              return this.data.verification.isCompleted
            }
          },
          {
            name: 'Company Info',
            route: {name: 'BusinessCompany', params: {hash: this.hash}},
            menu: true,
            title: true,
            isCompleted: () => {
              return this.data['company-business'].isCompleted
            }
          },
          {
            name: 'Legal address',
            route: {name: 'LegalAddress', params: {hash: this.hash}},
            menu: true,
            title: true,
            isCompleted: () => {
              return this.data.address.isCompleted['LEGAL']
            }
          },
          {
            name: 'Operating address',
            route: {name: 'BusinessOperatingAddress', params: {hash: this.hash}},
            menu: true,
            title: true,
            isCompleted: () => {
              return this.data.address.isCompleted['OPERATING']
            }
          },
          {
            name: 'Director or attorney',
            route: {name: 'BusinessDirector', params: {hash: this.hash}},
            menu: true,
            title: true,
            isCompleted: () => {
              return this.data.member.isCompleted['DIRECTOR']
            }
          },
          {
            name: 'UBO',
            route: {name: 'BusinessUBO', params: {hash: this.hash}},
            menu: true,
            title: true,
            isCompleted: () => {
              return this.data.member.isCompleted['UBO']
            }
          },
          {
            name: 'Business partners',
            route: {name: 'BusinessPartners', params: {hash: this.hash}},
            menu: true,
            title: true,
            isCompleted: () => {
              return this.data.counterparty.isCompleted['INCOMING_PAYMENTS']
                  && this.data.counterparty.isCompleted['OUTGOING_PAYMENTS']
            }
          },
          {
            name: 'KYC for business',
            route: {name: 'BusinessKYC', params: {hash: this.hash}},
            menu: true,
            title: true,
            isCompleted: () => {
              return this.data.kyc.isCompleted
            }
          },
          {
            name: 'Finish',
            route: {name: 'Finish', params: {hash: this.hash}},
            menu: true,
            title: true
          },
          {
            name: 'Success',
            route: {name: 'RegistrationCompleted', params: {hash: this.hash}},
            menu: false,
            title: false
          }
        ],
        individual: [
          {
            name: 'Create Account',
            route: {name: 'CreateAccount'},
            menu: false,
            title: true,
            isCompleted: () => {
              return this.data.user.isCompleted
            }
          },
          {
            name: 'Verification',
            route: {name: 'Verification', params: {hash: this.hash}},
            menu: false,
            title: false,
            isCompleted: () => {
              return this.data.verification.isCompleted
            }
          },
          {
            name: 'Contact info',
            route: {name: 'IndividualContactInfo', params: {hash: this.hash}},
            menu: true,
            title: true,
            isCompleted: () => {
              return this.data['company-individual'].isCompleted
            }
          },
          {
            name: 'Finish',
            route: {name: 'Finish', params: {hash: this.hash}},
            menu: true,
            title: true
          },
          {
            name: 'Success',
            route: {name: 'RegistrationCompleted', params: {hash: this.hash}},
            menu: false,
            title: false
          }
        ]
      }
    }
  },
  mounted() {
    // Save menu position
    window.document.onscroll = () => {
      this.menuFixed = window.scrollY > 120;
    }
  }
}
</script>
