<template>
  <div class="icon-burger" :style="styles">
    <span class="icon-burger-line" :style="lineStyle"></span>
    <span class="icon-burger-line" :style="lineStyle"></span>
    <span class="icon-burger-line" :style="lineStyle"></span>
  </div>
</template>

<script>
export default {
  name: 'IconBurger',
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    size: {
      type: [String, Number],
      default: 18
    }
  },
  computed: {
    styles () {
      return {
        width: this.size + 'px',
        height: this.size + 'px'
      }
    },
    lineStyle () {
      return {
        'background-color': this.color
      }
    }
  }
}
</script>

<style lang="less">
  .icon-burger {
    position: relative;

    &-line {
      display: block;
      height: 2px;
      width: calc(100% - 2px);
      margin-left: 1px;

      position: absolute;
      left: 0;

      top: 50%;
      transform: translateY(-50%);

      &:first-child {
        top: 2px;
        transform: none;
      }

      &:last-child {
        top: 14px;
        transform: none;
      }
    }
  }
</style>
