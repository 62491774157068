<template>
  <transition name="fade">
    <div :class="{mobile: opened}">

      <div class="mobile-menu-title">Menu</div>
      <span class="icon-circle icon-circle-menu" v-if="!opened" @click="opened = true"><icon-burger color="#19191C"/></span>
      <span class="icon-circle icon-circle-close" v-else @click="opened = false"><icon-close color="#19191C"/></span>

      <ul class="navigation-menu" :class="{fixed: fixed}">
        <router-link
            tag="li"
            active-class="active"
            v-for="item in menu"
            v-bind:key="item.name"
            :to="item.route"
            :class="getClass(item)"
        >
          <span>{{ item.name }}</span>
        </router-link>
      </ul>
    </div>
  </transition>
</template>

<script>
import IconClose from "@/assets/icons/IconClose";
import IconBurger from "@/assets/icons/IconBurger";
import {mapState} from "vuex";

export default {
  name: 'Menu',
  components: {IconClose, IconBurger},
  props: {
    items: {
      type: [Array],
      default () {
        return []
      }
    },
    fixed: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    '$route' () {
      this.opened = false
    }
  },
  data () {
    return {
      opened: false,
      menu: []
    }
  },
  computed: {
    ...mapState({
      registrationHash: state => state.registration.hash,
      registrationData: state => state.registration.data
    }),
  },
  created() {
    this.menu = this.items.filter(item => {
      return item.menu === true
    })
  },
  methods: {
    getClass (item) {
      let cl = null

      if (typeof item.isCompleted === "function") {
        cl = item.isCompleted()
      }

      if (cl === true) {
        return 'completed'
      } else if(cl === false) {
        return 'error'
      } else {
        return 'default'
      }
    }
  }
}
</script>


<style lang="less">
@import "../assets/css/media.less";
@yellow: #FDE46A;

.navigation-menu {
  width: min-content;
  //box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.3);
  border-radius: 3px;

  li {
    font-size: 14px;
    counter-increment: section;

    span {
      padding: 20px 45px 20px 35px;
      display: block;
      background: #fff;
      white-space: nowrap;
      vertical-align: middle;
      cursor: pointer;
      position: relative;

      &::before {
        content: counter(section);
        width: 18px;
        height: 18px;
        background-color: #FFFFFF;
        color: #19191C;
        margin-right: 10px;
        font-size: 12px;
        display: inline-block;
        border-radius: 100%;
        vertical-align: baseline;
        text-align: center;
        line-height: 18px;
      }
    }

    &.active {
      position: relative;
      z-index: 1;

      span {
        box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        padding: 20px 35px 20px 45px;
        width: calc(100% + 20px);
        position: relative;
        left: -10px;
        right: -10px;
        transition: box-shadow .4s ease, background-color .2s ease;

        &::before {
          background-color: #19191C;
          color: #fff;
          transition: background .1s linear, background-color .2s ease;
        }
      }
    }

    &.completed:not(.active) {
      span {
        background: #ffed9b;

        &::before {
          background-color: #19191C;
        }

        &::after {
          content: '';
          position: absolute;
          display: block;
          left: 42px;
          width: 3px;
          height: 7.5px;
          border: solid @yellow;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          top: 23px;
        }
      }
    }
    &.error {
      span {
        &:before {
          background-color: #fd0000;
          color: #ffffff;
        }
      }
    }

    &:first-child:not(.active) {
      span {
        border-radius: 3px 3px 0 0;
      }
    }
    &:last-child:not(.active)  {
      span {
        border-radius: 0 0 3px 3px;
      }
    }

    &:last-child {
      span:before  {
        background-image: url("/static/images/svg/IconSmile.svg");
        background-size: cover;
        background-position: center;
        background-color: #19191C;
        content: '';
        vertical-align: middle;
      }

      &.active {
        span:before {
          background-image: url("/static/images/svg/IconSmileYellow.svg");
        }
      }
    }
  }
}
.navigation-menu.fixed {
  position: fixed;
  top: 50px;
}

.mobile-menu-title,
.icon-circle {
  display: none;
}

@media @media_xs {
  .navigation-menu {
    display: none;
  }

  .icon-circle-menu {
    display: block;
  }

  .icon-circle {
    position: absolute;
    right: 20px;
    top: 35px;
    text-align: center;
    padding: 6px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    cursor: pointer;
    transition: all .2s ease;

    .icon-burger {
      position: relative;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    span.close {
      margin-top: 3px;
    }

    &:hover {
      transform: rotate(180deg);
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
    }
  }

  .mobile {
    position: fixed;
    background-color: @yellow;
    left: 0;
    top: 0;

    width: 100%;
    height: 100vh;

    z-index: 100;

    .navigation-menu {
      margin: 100px 20px;
      box-sizing: border-box;
      width: auto;
      position: relative;
      display: inherit;

      li.active span {
        left: -2px;
        right: -2px;
        width: calc(100% + 4px);
      }
    }

    .icon-circle-close {
      display: block;
    }

    .mobile-menu-title {
      font-weight: 600;
      font-size: 40px;
      position: absolute;
      top: 30px;
      left: 20px;
      display: block;
    }
  }
}
</style>
